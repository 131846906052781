import { ComponentType, useCallback, useEffect, useState } from "react"
import { useServer } from "./providers/server"
import liff from '@line/liff';
import { useNavigate } from "react-router-dom";
import StampNumPad, { StampNumPadViewType } from "./StampNumPad";

const Stamp: ComponentType<Props> = ({ View, NumPadView }) => {
    const navigate = useNavigate()
    const server = useServer()
    const [point, setPoint] = useState<number | undefined>(undefined)
    const [isShowNumPad, setIsShowNumPad] = useState(false)
    const fetchAsync = useCallback(async () => {
        const stamp = await server.getStamp()
        setPoint(stamp.point)
    }, [server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    useEffect(() => {
        window.document.title = "ポイント"
    }, [])
    if (point == null) {
        return <></>
    }
    if (isShowNumPad) {
        return <StampNumPad
            View={NumPadView}
            onCancel={() => setIsShowNumPad(false)}
            onSubmit={async pin => {
                try {
                    await server.addStamp(pin)
                    await fetchAsync()
                    setIsShowNumPad(false)
                } catch(error) {
                    setIsShowNumPad(false)
                }
            }}
        />
    }
    return (
        <View
            point={point}
            onClickShowQRReader={async () => {
                const ret = await liff.scanCodeV2()
                if (ret.value == null) {
                    return
                }
                await server.addStamp(ret.value!)
                await fetchAsync()
            }}
            onClickShowNumPad={() => setIsShowNumPad(true)}
            onClickExchange={() => {
                navigate('/stamp/gifts')
            }}
        />
    )
}

interface Props {
    View: StampViewType
    NumPadView: StampNumPadViewType
}

export default Stamp

export type StampViewType = ComponentType<{
    point: number,
    onClickShowNumPad: () => any,
    onClickShowQRReader: () => any,
    onClickExchange: () => any,
}>
